import { BackgroundImage, Box, Card, Center, Grid, Group, Image, Stack, Text } from '@mantine/core';
import React from 'react';

const FeaturedOn = () => {
    const featuredOn = [
        {
            title: "Digital News Asia",
            img: "/digital-news-asia.png",
            preview: "/dna-preview.png",
            text: "MYStartup selects 26 startups for their pre-accelerator cohort 3",
            credit: "By Digital News Asia March 16, 2024",
            link: "https://www.digitalnewsasia.com/startups/mystartup-selects-26-startups-their-pre-accelerator-cohort-3"

        },
        {
            title: "Tech Node Global",
            img: "/tnglobal-logo-light.png",
            preview: "/tnglobal-preview.png",
            text: "MALAYSIA'S MYSTARTUP SELECTS 26 STARTUPS FOR PRE-ACCELERATOR COHORT 3 PROGRAM",
            credit: "By Technode Global Staff March 15, 2024",
            link: "https://technode.global/2024/03/15/malaysias-mystartup-selects-26-startups-for-pre-accelerator-cohort-3-program/"
        },
        {
            title: "Vulcan Post",
            img: "/vulcan.png",
            preview: "/vulcan-preview.png",
            text: "26 out of 136 startups were chosen for MYStartup's 3rd pre-accelerator, here's what they do",
            credit: "By Claudia Khaw, Vulcan Post March 19, 2024",
            link: "https://vulcanpost.com/854831/mystartup-pre-accelerator-cohort-3-startups-malaysia/"
        },
    ]

    return (
        <>
            {/* <Text
                component="h1"
                sx={(theme) => ({
                    fontSize: "1.5em",
                    lineHeight: 1,
                    [theme.fn.smallerThan("xs")]: {
                        textAlign: "center",
                    },
                })}
                align="center"
            >
                Featured On
            </Text> */}
            <Grid gutter={24}>
                {featuredOn.map((logo) => (
                    <Grid.Col xs={12} sm={6}>
                        <Card
                            p={16}
                            onClick={() => window.open(logo.link, "_newtab")}
                            shadow="md"
                            withBorder
                            sx={{
                                cursor: "pointer",
                                borderRadius: 16,
                                height: "100%"
                            }}
                        >
                            <Stack spacing={8} h="100%">
                                <Stack sx={{ flex: 1 }}>
                                    <BackgroundImage
                                        src={logo.preview}
                                        sx={{ height: 312, width: '100%', borderRadius: 8, backgroundPosition: 'top', backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
                                    >
                                        <img src={logo.preview} alt={`${logo.title}-preview`} className='sr-only' />
                                    </BackgroundImage>
                                    <Box component="article" sx={{ flex: 1 }}>
                                        <Stack spacing={2}>
                                            <Text component="h1" fz={24} sx={{ color: "#2CB568" }}>{logo.text}</Text>
                                        </Stack>
                                    </Box>
                                </Stack>
                                <Group w={'100%'} position="right" sx={{background: "#d5d5d5", borderRadius: 8}} px={16}>
                                    <Text size="xs" >{logo.credit}</Text>
                                    <BackgroundImage
                                        src={logo.img}
                                        h={64}
                                        w={64}
                                        sx={{ backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition:"center" }}
                                    >

                                    </BackgroundImage>
                                </Group>
                            </Stack>
                        </Card>
                    </Grid.Col>
                ))}
            </Grid>
        </>
    );
};

export default FeaturedOn;