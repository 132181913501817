import { Carousel, Embla } from '@mantine/carousel';
import { BackgroundImage, Button, Card, Image, Stack, Text, Title, Badge, Group } from '@mantine/core';
import { useListingAPI } from 'api/useListingAPI';
import PropRequestCard from 'components/PropRequest/PropRequestCard';
import TransactionTypeBadge from 'components/TransactionTypeBadge';
import { PROPERTY_REQUEST_COLOR_SETTINGS } from 'helpers/constants';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ListingsRiver = () => {
    const { useGetLandingListings } = useListingAPI();
    const [propReqArr, setPropReqArr] = useState<any[]>([])
    const [embla, setEmbla] = useState<Embla | null>(null);
    const navigate = useNavigate()

    const { data, isLoading, error, refetch } = useGetLandingListings();

    useEffect(() => {
        let result = data as any
        if (!!data && !!result.data && !isLoading) {
            console.log("result", result)
            const formatted = result.data.filter((d: any) => !!d.images && d.images.length > 0).map((r: any) => {
                return {
                    id: r?.id,
                    title: r?.title,
                    image: r?.images[0],
                    price: r?.price,
                    area: r?.area,
                    state: r?.state,
                    propertyType: r?.propertyType,
                    transactionType: r.transactionType,
                    isDeveloperListing: r.isDeveloperListing
                }
            })

            console.log("formatted", formatted)
            setPropReqArr(formatted)
        }
    }, [data])

    useEffect(() => {
        if (!!embla) {
            console.log(embla)
            setInterval(() => {
                embla?.scrollNext()
            }, 10000)
        }
    }, [embla])

    if (!propReqArr) {
        return <></>
    }

    return (
        <Stack>
            {/* <Title order={3}>Current Listings</Title> */}
            <Carousel
                // height={200}
                slideGap="md"
                loop
                align="start"
                breakpoints={[
                    { minWidth: 'xl', slideSize: '33%' },
                    { minWidth: 'md', slideSize: '50%' },
                    { minWidth: 'sm', slideSize: '100%' },
                ]}
                withControls={false}
                getEmblaApi={setEmbla}
            >
                {propReqArr.map((d, i) => (
                    <Carousel.Slide key={`landing-propreq-${i}`}>
                        <Card withBorder shadow='lg' radius={16} w="100%">
                            <Stack>
                                <BackgroundImage
                                    src={d.image.url}
                                    w={'100%'}
                                    h={250}
                                    radius={8}
                                />
                                <Stack spacing={4} align='flex-start'>
                                    <Group spacing={8}>
                                        {!!d.isDeveloperListing && (
                                            <Badge color="orange" variant="filled">
                                                Developer Listing
                                            </Badge>
                                        )}
                                        <TransactionTypeBadge transactionType={d.transactionType} />
                                    </Group>
                                    <Title order={4} lineClamp={1}>{d.title}</Title>
                                    <Text>{d.area}, {d.state}</Text>
                                    <Title order={2}>RM{d.price}</Title>
                                </Stack>
                                <Button onClick={() => navigate(`/listings/${d.id}`)} size='lg'>See More</Button>
                            </Stack>
                        </Card>
                    </Carousel.Slide>
                ))}
            </Carousel>
        </Stack>
    );
};

export default ListingsRiver;