import { Box } from '@mantine/core';
import React from 'react';

const PatternBox = ({ ...props }) => {
    return (
        <Box
            sx={(theme)=>({
                backgroundImage: "url('./landingv3/propmoth-background-pattern.png')",
                [theme.fn.smallerThan("xs")]: {
                    backgroundImage: "url('./landingv3/propmoth-background-pattern-mobile.png')",
                },
                backgroundSize: "contain",
                backgroundPosition: 'top',
                backgroundRepeat: "repeat-y"
            })}
            {...props}
        >
            {props.children}
        </Box>
    );
};

export default PatternBox;