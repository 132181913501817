import { strictNumberInput, validateNotEmpty } from "../helpers/validations";
import {
    Box,
    Button,
    Container,
    PasswordInput,
    Grid,
    UnstyledButton,
    Text,
    TextInput,
    Center,
    Group,
    ActionIcon,
    Image,
    Stack,
    Divider,
    Card,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Permission, useAuth } from "../hooks/useAuth";
import { USER_ACTIONS } from "../context/user/userActions";
import { BaseFormInput } from "components/Inputs/BaseFormInput";
import { BasePasswordInput } from "components/Inputs/BasePasswordInput";
import { RegisterTitle } from "components/Header/userHeader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { USER_TYPE } from "helpers/constants";
import { BrandGoogle } from "tabler-icons-react";
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { sanitizePhoneNumber } from "helpers/utility";
import SocialLogins from "components/SocialLogins";
import { notifications } from "@mantine/notifications";
import { useListingAPI } from "api/useListingAPI";

export const Login: React.FC<any> = ({ socket, auth }) => {
    const { loginMutation, userDispatch } = useAuth(Permission.GUEST_ONLY);
    const [searchParams, setSearchParams] = useSearchParams();
    const [emailLogin, setEmailLogin] = useState(false)
    

    const [formError, setFormError] = useState("");
    const navigate = useNavigate();
    const form = useForm({
        initialValues: {
            identifier: "",
            password: "",
        },
        validate: {
            identifier: (value) => validateNotEmpty(value),
            password: (value) => validateNotEmpty(value),
        },
    });

    useEffect(() => {
        if (!!searchParams && !!searchParams.get("redirect_url")) {
            let url = searchParams.get("redirect_url")
            sessionStorage.setItem("redirect_url", url ?? "/profile")
        }

    }, [searchParams])

    const handleLogin = useCallback(async (values: { identifier: string; password: string }) => {
            
        let payload = {
            ...values,
        }

        if(!emailLogin){
            payload.identifier = `60${sanitizePhoneNumber(`${values.identifier}`)}`
        }
        
        await loginMutation.mutateAsync(
            payload,
            {
                onSuccess: ({ data }: any) => {
                    userDispatch({
                        type: USER_ACTIONS.SET,
                        payload: {
                            jwt: data.jwt,
                            refreshToken: data.refreshToken ?? "",
                            blocked: data.user?.blocked,
                            confirmed: data.user?.confirmed,
                            createdAt: new Date(data.user?.createdAt),
                            email: data.user?.email,
                            id: data.user?.id,
                            provider: data.user?.provider,
                            updatedAt: new Date(data.user?.updatedAt),
                            username: data.user?.username,
                            fullname: data.user?.fullname,
                            userType: data.user?.userType,
                            checkoutId: data.user?.checkoutId,
                            status: data.user?.status,
                            loaded: true,
                        },
                    });

                    const postLoginRedirect = sessionStorage.getItem("redirect_url")

                    if (!!postLoginRedirect) {
                        navigate(postLoginRedirect)
                        sessionStorage.removeItem("redirect_url")
                    } else {
                        // if (data.user?.userType === USER_TYPE.AGENT) {
                        //     navigate("/requests/search");
                        // } else {
                        // }
                        navigate("/profile");
                    }

                },
                onError: (error: any) => {
                    // console.log("error", error);
                    setFormError("Incorrect phone number or password");
                    //   setFormError(error.response.data.error.message);
                },
            }
        );
    },[emailLogin])

    const [socialUser] = useAuthState(auth);

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider);
    };

    // useEffect(() => {
    //     console.log(socialUser);
    // }, [socialUser]);

    // const googleLoginSuccess = (credentialResponse: any) => {
    //     console.log(credentialResponse);
    // };

    return (
        <form onSubmit={form.onSubmit((values) => handleLogin(values))}>
            <Grid m={0}>
                <Grid.Col
                    p={0}
                    md={4}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("md")]: {
                            display: "none",
                        },
                    })}
                >
                    <Box
                        sx={{
                            backgroundImage: `url('https://propmoth-images.s3.ap-southeast-1.amazonaws.com/section_img_8d0c5bba4d.png?updated_at=2023-06-26T15:43:56.919Z')`,
                            width: "100%",
                            height: "100%",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            minHeight: "calc(100vh - 60px)",
                        }}
                    />
                </Grid.Col>
                <Grid.Col md={8} xs={12} p={0}>
                    <Container
                        size="450px"
                        sx={(theme) => ({
                            [theme.fn.largerThan("md")]: {
                                paddingTop: "96px",
                                paddingBottom: "96px",
                            },
                            [theme.fn.smallerThan("md")]: {
                                paddingTop: "24px",
                                paddingBottom: "24px",
                            },
                            display: "flex",
                            flexDirection: "column",
                            gap: 16,
                        })}
                    >
                        {!!searchParams && !!searchParams.get("expiredToken") && (
                            <Card sx={{background: '#ff3333'}}>
                                <Text fw={700} color="white">Your session has expired, please login again</Text>
                            </Card>
                        )}
                        <RegisterTitle
                            title="Log in"
                            subtitle="Welcome back to Propmoth"
                        />
                        {!!emailLogin ? (
                            <BaseFormInput
                                label="Email"
                                {...form.getInputProps("identifier")}
                            />
                        ):(
                            <BaseFormInput
                                type="number"
                                label="Phone Number"
                                icon={
                                    <Text
                                        color="black"
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {`MY (+60)`}
                                    </Text>
                                }
                                onKeyDown={(evt: any) => strictNumberInput(evt)}
                                iconWidth={90}
                                {...form.getInputProps("identifier")}
                            />
                        )}
                        <Box>
                            <BasePasswordInput
                                label="Password"
                                {...form.getInputProps("password")}
                            />
                            <UnstyledButton
                                onClick={() => navigate("/forgot-password")}
                            >
                                <Text weight={500} underline>
                                    Forgot password?
                                </Text>
                            </UnstyledButton>
                        </Box>
                        {!!formError && (
                            <Text size="sm" color="red">
                                {formError}
                            </Text>
                        )}
                        <Stack spacing={8}>
                            <Button variant="subtle" onClick={() => setEmailLogin((prev) => !prev)}>
                                {!!emailLogin ? "Login with Phone Number" : "Login with Email"}
                            </Button>
                            <Button
                                size="lg"
                                radius={10}
                                mt={16}
                                // onClick={() => formSubmit(form.values)}
                                type="submit"
                                fullWidth
                                loading={loginMutation.isLoading}
                            >
                                Log In
                            </Button>
                        </Stack>
                        <Box mt={16}>
                            <Text size={16} align="center">
                                Don't have an account yet?&nbsp;
                                <UnstyledButton>
                                    <Text
                                        component="span"
                                        underline
                                        weight={500}
                                        onClick={() => navigate("/register")}
                                    >
                                        Join Us
                                    </Text>
                                </UnstyledButton>
                            </Text>
                        </Box>
                        {/* <Divider
                            my={16}
                            label={
                                <Text size="sm" align="center">
                                    Or
                                </Text>
                            }
                            labelPosition="center"
                        />
                        <Stack sx={{ width: "100%" }} justify="center">
                            <SocialLogins />
                        </Stack> */}
                    </Container>
                </Grid.Col>
            </Grid>
        </form>
        // <Container>
        //     <form onSubmit={form.onSubmit((values) => handleLogin(values))}>
        //         <TextInput
        //             {...form.getInputProps("identifier")}
        //             label="Phone Number "
        //             type="number"
        //             onKeyDown={(evt) => strictNumberInput(evt)}
        //         />
        //         <PasswordInput
        //             label="Password"
        //             {...form.getInputProps("password")}
        //         />
        //         <Button type="submit">Login</Button>
        //     </form>
        // </Container>
    );
};
