import { Accordion, BackgroundImage, Box, Button, Container, Grid, Group, Image, Stack, Text } from '@mantine/core';
import FeaturedAgencies from 'pages/MainLanding/FeaturedAgencies';
import ListingsRiver from 'pages/MainLanding/ListingsRiver';
import OtherAccounts from 'pages/MainLanding/OtherAccounts';
import PropRequestRiver from 'pages/MainLanding/PropRequestRiver';
import { Link, Rocket, Spy } from 'tabler-icons-react';
import GreenGradientBg from './GreenGradientBg';
import PatternBox from './PatternBox';
import HowItWorks from 'pages/MainLanding/HowItWorks';
import InPartnershipWith from 'pages/MainLanding/InPartnershipWith';
import SupportedBy from 'pages/MainLanding/SupportedBy';
import FeaturedOn from 'pages/MainLanding/FeaturedOn';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CountUp } from 'countup.js';
import CustomHeader from 'components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { Permission, useAuth } from 'hooks/useAuth';
import { useListingAPI } from 'api/useListingAPI';
import { USER_TYPE } from 'helpers/constants';

const LandingV3 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);
    const navigate = useNavigate()
    const { user } = useAuth(Permission.ANYONE);
    const { useGetMyPropRequests } = useListingAPI();
    const { data, isLoading, error, refetch } = useGetMyPropRequests(
        user?.id.toString() || "0"
    );

    const ctaNavigateLink = useMemo(() => {
        if (!!user.jwt) {
            if (user.userType === USER_TYPE.LISTER) {
                if (!!data && !isLoading) {
                    if (data.length > 0) {
                        return "/profile"
                    }
                }
                return "/requests/create";
            }

            if (user.userType === USER_TYPE.AGENT) {
                return "/profile?tab=leads";
            }
        }

        return "/requests/create";
        // return "/register/lister";
    }, [user, data, isLoading]);

    const stats = [
        {
            key: 0,
            title: "100",
            subtitle: "Verified Participating Agencies",
            content: "Verified participating agencies that work hard to make sure you find the perfect place"
        },
        {
            key: 1,
            title: "8",
            subtitle: "Developers Partnered",
            content: "Developers partnered with us, which gives you direct access to their sales departments",
        },
        {
            key: 2,
            title: "300",
            subtitle: "Genuine REN Agents",
            content: "Genuine REN Agents registered and are ready to fight over your business with their best offers.",
        },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            // entries is an array of observed elements
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    console.log("is Visible")


                } else {
                    setIsVisible(false);
                }
            });
        }, {
            root: null, // relative to document viewport 
            rootMargin: '0px', // margin around root. Values are similar to css margin
            threshold: 0.5 // threshold of 0.5 means 50% should be visible for callback to fire
        });

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, []); // Empty dependency array means the effect only runs once


    const whyPropmoth = [
        {
            title: "Direct to Developer",
            content: (
                <Text>
                    Get direct access to projects via{" "}
                    <Text component="span" sx={{ color: "#407A3F" }}>
                        sponsored offers
                    </Text>{" "}
                    by developers when your details match
                </Text>
            ),
            icon: <Link width={128} height={128} color="white" />,
        },
        {
            title: "Anonimity",
            content: (
                <Text>
                    Keep your personal number and emails private to{" "}
                    <Text component="span" sx={{ color: "#407A3F" }}>
                        prevent unwanted spam
                    </Text>{" "}
                    from agents
                </Text>
            ),
            icon: <Spy width={128} height={128} color="white" />,
        },
        {
            title: "Efficiency",
            content: (
                <Text>
                    Every offer you browse is genuine. Agents will no longer
                    waste your time with{" "}
                    <Text component="span" sx={{ color: "#407A3F" }}>
                        fake listings
                    </Text>
                </Text>
            ),
            icon: <Rocket width={128} height={128} color="white" />,
        },
    ];

    const ourExistence = [
        {
            title: (<Text ta="center">Bad Experience & <br /> Horrible Offers</Text>),
            content: "Agents often keep certain properties away from clients because there's not enough commission to be made, even though those properties would be the perfect fit.",
            icon: "/landingv3/existence-1.png",
        },
        {
            title: "Guaranteed Security",
            content: "On Propmoth , all contact takes place in our chat, no one will get your personal information from us.",
            icon: "/landingv3/existence-2.png",
        },
        {
            title: "Curated to Your Needs",
            content: "Simply put, good agents will find properties that fit your needs and bad agents will be driven out of the market, creating the perfect environment for serious clients.",
            icon: "/landingv3/existence-3.png",
        },
    ];

    const faq = [
        {
            key: 0,
            title: "Do I need to pay?",
            content:
                "No, not at all. Other than telling us your budget, we will never ask you for any financial information. We will never email you asking for your financial information either.",
        },
        {
            key: 1,
            title: "Is it safe?",
            content:
                "Yes, aside from certain pieces of information that you input to help you get the optimal property offers, you control all your personal data on our platform. Until you say so, your info is not available to anyone, even Propmoth. However, Agent data is always publicly available for you to check before you engage with them.",
        },
        {
            key: 2,
            title: "I signed up, now what?",
            content:
                "First off, thank you for signing up! You can now create a property request according to your perfect requirements which states your budget, type and even style of property! Agents will then offer you property according to your details and you can then look through and select the ones you like and start talking to them to find out more!",
        },
    ];


    return (
        <Stack spacing={0} sx={{ position: "relative" }}>
            <Box sx={{ position: "absolute", top: 0, left: 0, width: '100%', zIndex: 100 }}>
                <CustomHeader isLanding />
            </Box>
            <Box
                sx={(theme) => ({
                    height: "70vh",
                    // height: 400,
                    // background: "#2EB568",
                    // backgroundImage: "url('./landingv3/header-bg.png')",
                    // backgroundPosition: "center",
                    // backgroundRepeat: "no-repeat",
                    // backgroundImage: "url('./landingv3/propmoth-background-pattern.png')",
                    // backgroundSize: "contain",
                    // backgroundPosition: 'top',
                    // backgroundRepeat: "repeat-y",
                    background: "linear-gradient(180deg, rgba(46,181,104,1) 0%, rgba(225,255,238,1) 50%, rgba(248,249,250,1) 100%)",
                    position: "relative",
                    width: '100vw',
                    paddingTop: 88,
                    backgroundImage: "url('https://propmoth-assets.s3.ap-southeast-1.amazonaws.com/Landing_Animation_FINAL_gif_landing.gif')",
                    backgroundPosition: "bottom",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    // [theme.fn.smallerThan("lg")]: {
                    //     height: 340,
                    // },
                    [theme.fn.smallerThan("xs")]: {
                        height: 290,
                    },
                })}
            >
                {/* <Box
                    component="video"
                    src="/landingv3/Landing_Animation_FINAL.mp4"
                    width={"100%"}
                    sx={(theme) => ({
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        [theme.fn.smallerThan("xs")]: {
                            display: 'none'
                        },
                    })}
                    autoPlay
                    muted
                    loop
                >
                </Box> */}
            </Box>
            <PatternBox>
                <Container 
                    maw={1200} 
                    p={72}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("md")]: {
                            paddingTop: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            
                        },
                    })}
                >
                    <Text fw="bold" component="h1" ta={'center'} fz={50}>Find <Text component='span' color='#2EB568'>exactly</Text> what you're looking for</Text>
                    <Text component='h2' ta={'center'} fw={500} fz={29}>List your dream home location and budget, professional <br /> agents are the ones who will be reaching out this time.</Text>
                    <Text component='h3' ta={'center'} fz={32}>We're giving back power to the users.</Text>
                    <Group position='center'>
                        <Button sx={{ backgroundColor: '#2EB568', borderRadius: 40 }} px={77} size='xl' onClick={() => navigate(ctaNavigateLink)}>
                            Start Now
                        </Button>
                    </Group>
                </Container>
            </PatternBox>
            <GreenGradientBg>
                <Container 
                    size={1200} 
                    py={72} 
                    w="100%"
                    sx={(theme) => ({
                        [theme.fn.smallerThan("xs")]: {
                            paddingLeft: 16,
                            paddingRight: 16,
                        },
                    })}
                >
                    <Stack spacing={24}>
                        <Text fw="bold" fz={46} ta="center">Current <Text component='span' color='#2EB568'>Property Requests</Text></Text>
                        <PropRequestRiver />
                        <Group position='center'>
                            <Button sx={{ backgroundColor: '#2EB568', borderRadius: 40 }} px={77} size='xl' onClick={() => navigate("/register/agent")}>
                                View Current Requests
                            </Button>
                        </Group>
                    </Stack>
                </Container>
            </GreenGradientBg>
            <PatternBox>
                <Container 
                    maw={1200} 
                    py={72}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("xs")]: {
                            paddingLeft: 16,
                            paddingRight: 16,
                        },
                    })}
                >
                    <Stack spacing={24}>
                        <Text fw="bold" fz={46} ta="center">Current <Text component='span' color='#2EB568'>Listings</Text></Text>
                        <ListingsRiver />
                    </Stack>
                </Container>
            </PatternBox>
            <GreenGradientBg>
                <Text fw="bold" fz={46} ta="center"><Text component='span' color='#2EB568'>Not Looking </Text> for Property?</Text>
                <OtherAccounts />
            </GreenGradientBg>
            <PatternBox py={64}>
                <Container 
                    maw={1200}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("xs")]: {
                            paddingLeft: 16,
                            paddingRight: 16,
                        },
                    })}
                >
                    <Stack spacing={64}>
                        <Stack>
                            <Group position='center' spacing={8}>
                                <Text fw="bold" fz={46} color='#2EB568'>Why</Text>
                                <Image
                                    src={"/logo.svg"}
                                    alt={"propmoth logo"}
                                    width={197}
                                    height={64}
                                />
                                <Text fw="bold" fz={46} color='#2EB568'>?</Text>
                            </Group>
                            <Stack spacing={4}>
                                <Text fz={27} ta="center">You're the one paying</Text>
                                <Text fz={27} ta="center">so why shouldn't you get the <Text fw="bold" component="span">best offers</Text>?</Text>
                            </Stack>
                        </Stack>
                        <Grid>
                            {whyPropmoth.map((p) => (
                                <Grid.Col md={4}>
                                    <Stack align="center">
                                        <Box sx={{ background: "#2CB568", borderRadius: "50%" }} p={8}>
                                            {p.icon}
                                        </Box>
                                        <Text color="#2CB568" fw="bold" fz={28}>{p.title}</Text>
                                        <Text fz={17} ta="center" fw="bold">{p.content}</Text>
                                    </Stack>
                                </Grid.Col>
                            ))}
                        </Grid>
                        <Stack spacing={0}>
                            <Text fw="bold" fz={46} ta="center">Transparency above all else</Text>
                            <Text fw="bold" fz={46} ta="center" color='#2EB568'>Genuine properties for Genuine People</Text>
                        </Stack>
                        <Text ta="center" fz={27}>
                            Be part of the growing force fighting back against the predatory <br /> industry of property. Get what you want and nothing else. <Text component="span" fw="bold">Never be <br /> afraid of fake listings ever again</Text>.
                        </Text>
                        <Grid ref={elementRef}>
                            {stats.map(s => (
                                <Grid.Col md={4}>
                                    <Stack>
                                        <Text
                                            ta="center"
                                            fw="bold"
                                            fz={120}
                                            gradient={{ from: "#2EB568", to: "#E1FFEE", deg: 0 }}
                                            color="#2CB568"
                                            id={`stats-${s.key}`}
                                        >
                                            {s.title}
                                        </Text>
                                        <Text
                                            ta="center"
                                            fw="bold"
                                            fz={28}
                                            color="#2CB568"
                                        >
                                            {s.subtitle}
                                        </Text>
                                        <Text
                                            ta="center"
                                            fz={17}
                                        >
                                            {s.content}
                                        </Text>
                                    </Stack>
                                </Grid.Col>
                            ))}
                        </Grid>
                    </Stack>
                </Container>
            </PatternBox>
            <GreenGradientBg>
                <Container 
                    maw={1200} 
                    p={72}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("xs")]: {
                            paddingLeft: 16,
                            paddingRight: 16,
                        },
                    })}
                >
                    <Stack spacing={64}>
                        <Text fw="bold" fz={46} ta="center">Featured <Text component='span' color='#2EB568'>Agencies</Text></Text>
                        <FeaturedAgencies />
                    </Stack>
                </Container>
            </GreenGradientBg>
            <PatternBox py={64}>
                <Container 
                    maw={1200}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("xs")]: {
                            paddingLeft: 16,
                            paddingRight: 16,
                        },
                    })}
                >
                    <Stack spacing={64}>
                        <Text fw="bold" fz={46} ta="center">Our <Text component='span' color='#2EB568'>Existence</Text></Text>
                        <Grid>
                            {ourExistence.map((p) => (
                                <Grid.Col md={4}>
                                    <Stack align="center">
                                        <BackgroundImage src={p.icon} w="100%" h={128} sx={{ backgroundSize: "contain", backgroundRepeat: 'no-repeat', backgroundPosition: "center" }} />
                                        <Text color="#2CB568" fw="bold" fz={28}>{p.title}</Text>
                                        <Text fz={17} ta="center" fw="bold">{p.content}</Text>
                                    </Stack>
                                </Grid.Col>
                            ))}
                        </Grid>
                    </Stack>
                </Container>
            </PatternBox>
            <GreenGradientBg>
                <Container 
                    maw={1200} 
                    py={64}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("xs")]: {
                            paddingTop: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                        },
                    })}
                >
                    <Group>
                        <Stack spacing={8} sx={{ flex: 1 }}>
                            <Text fw="bold" fz={46}>Ready to <Text component='span' color='#2EB568'>make a difference?</Text></Text>
                            <Text color="#2CB568" fz={24}> Send in your requests today!</Text>
                        </Stack>
                        <Button sx={{ borderRadius: 40, fontSize: 24 }} color="dark" px={16} size="xl" onClick={() => navigate(ctaNavigateLink)}>
                            I'm Ready
                        </Button>
                    </Group>
                </Container>
            </GreenGradientBg>
            <PatternBox py={64}>
                <Container 
                    maw={1200} 
                    py={64}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("xs")]: {
                            paddingLeft: 16,
                            paddingRight: 16,
                        },
                    })}
                >
                    <Stack spacing={64}>
                        <Stack spacing={64}>
                            <Group position='center'>
                                <Button
                                    sx={{
                                        backgroundColor: '#2EB568',
                                        borderRadius: 40,
                                        fontSize: 39
                                    }}
                                    px={'xl'}
                                    size='xl'
                                    onClick={() => navigate(ctaNavigateLink)}
                                >
                                    How to get started?
                                </Button>
                            </Group>
                            <HowItWorks />
                        </Stack>
                        <Stack spacing={64}>
                            <Text fw="bold" fz={46} ta="center">Frequently Asked Questions <Text component='span' color='#2EB568'>(FAQ)</Text></Text>
                            <Accordion
                                variant="separated"
                                radius={"md"}
                                chevronSize={32}
                                styles={{
                                    control: {
                                        background: "#2EB568",
                                        color: "white"
                                    },
                                    panel: {
                                        background: "#2EB568",
                                        color: "white"
                                    }
                                }}
                            >
                                {faq.map(f => (
                                    <Accordion.Item value={`faq-${f.key}`}>
                                        <Accordion.Control>
                                            <Text fz={30} fw={700}>{f.title}</Text>
                                        </Accordion.Control>
                                        <Accordion.Panel>
                                            <Text fz={20}> {f.content}</Text>
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Stack>
                    </Stack>
                </Container>
            </PatternBox>
            <GreenGradientBg>
                <Container 
                    maw={1200} 
                    py={64}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("xs")]: {
                            paddingLeft: 16,
                            paddingRight: 16,
                        },
                    })}
                >
                    <Stack spacing={64}>
                        <Text fw="bold" fz={46} ta="center">In <Text component='span' color='#2EB568'>Partnership </Text> with</Text>
                        <InPartnershipWith />
                    </Stack>
                </Container>
            </GreenGradientBg>
            <PatternBox py={64}>
                <Container 
                    maw={1200} 
                    py={64}
                    sx={(theme) => ({
                        [theme.fn.smallerThan("xs")]: {
                            paddingLeft: 16,
                            paddingRight: 16,
                        },
                    })}
                >
                    <Stack spacing={64}>
                        <Stack spacing={64}>
                            <Text fw="bold" fz={46} ta="center">We are <Text component='span' color='#2EB568'>Featured </Text> on</Text>
                            <FeaturedOn />
                        </Stack>
                        <Stack spacing={64}>
                            <Text fw="bold" fz={46} ta="center"><Text component='span' color='#2EB568'>Supported </Text> by</Text>
                            <SupportedBy />
                        </Stack>
                    </Stack>
                </Container>
            </PatternBox>
        </Stack>
    );
};

export default LandingV3;