import { Center, Grid, Image, Stack, Text } from '@mantine/core';
import React from 'react';

const SupportedBy = () => {
    const supportedBy = [
        {
            title: "Cradle Malaysia",
            img: "/cradle.png",

        },
        {
            title: "MY Startup",
            img: "/MyStartUp.png",
        },
        {
            title: "MOSTI",
            img: "/MOSTI.png",
        },
        {
            title: "AWS Startups",
            img: "/aws-startup.jpeg",
        },
    ]

    return (
        <>
            <Stack
                py={24}
            >
                {/* <Text
                    component="h1"
                    sx={(theme) => ({
                        fontSize: "1.5em",
                        lineHeight: 1,
                        [theme.fn.smallerThan("xs")]: {
                            textAlign: "center",
                        },
                    })}
                    align="center"
                >
                    Supported By
                </Text> */}
                <Grid gutter={24}>
                    {supportedBy.map((logo) => (
                        <Grid.Col xs={12} sm={6} md={3}>
                            <Center sx={{ height: "100%" }}>
                                <Image
                                    src={logo.img}
                                    alt={logo.title}
                                    fit="contain"
                                    width={150}
                                />
                            </Center>
                        </Grid.Col>
                    ))}
                </Grid>
            </Stack>
        </>
    );
};

export default SupportedBy;