import { Anchor, Avatar, Badge, Box, Button, Card, Center, CopyButton, Flex, Group, Loader, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Calendar, Chat, CreditCard, Handshake, SquaresFour, UserPlus, UserSquare, UsersThree } from '@phosphor-icons/react';
import { SubscriptionBadge } from 'components/Badges';
import { useProfileController } from 'hooks/useProfile';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import { AgentDetail } from 'types/userTypes';

const DashboardControlCard = ({ currentActiveSubscription, activeTab, setActiveTab }: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { profileData, profileIsLoading, view, user, refetchProfile } =
        useProfileController();
    const theme = useMantineTheme();
    const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const profile = profileData as AgentDetail;
    const navigate = useNavigate()
    const [mobileMenuDrop, setMobileMenuDrop] = useState(false)

    const profileLinks = [
        {
            label: "Dashboard",
            value: "dashboard",
            icon: <SquaresFour />
        },
        {
            label: "My Subscription",
            value: "subscription",
            icon: <CreditCard />
        },
        {
            label: "Leads",
            value: "leads",
            icon: <UsersThree />
        },
        {
            label: "Developer Listings",
            value: "developer-listings",
            icon: <UsersThree />
        },
        {
            label: "Chats",
            value: "chats",
            icon: <Chat />
        },
        {
            label: "Referral",
            value: "referral",
            icon: <UserPlus />
        },
        {
            label: "Co-Agency",
            value: "coagency",
            icon: <Handshake />
        },
    ]

    const comingSoon = [
        {
            label: "Events",
            value: "events",
            icon: <Calendar />
        },
        {
            label: "Owner Listings",
            value: "owner-listings",
            icon: <UsersThree />
        },
    ]


    const displayedProfileLinks = useMemo(() => {
        if (!isMobile) {
            return profileLinks
        }

        return profileLinks.filter((v: any) => v.value !== activeTab)
    }, [isMobile, activeTab])

    useEffect(() => {
        console.log("profileData", profileData)
    }, [profileData])

    useEffect(() => {
        if (!!isTablet) {
            setMobileMenuDrop(false)
        }
    }, [isTablet])

    const setAndClose = (activeTab: string) => {
        navigate(`/profile?tab=${activeTab}`)
        // setActiveTab(activeTab)
        setMobileMenuDrop(false)
        // setSearchParams({});
    }

    const setAndSwap = (activeTab: string) => {
        // setActiveTab(activeTab)
        navigate(`/profile?tab=${activeTab}`)

        // setSearchParams({});
    }

    const isAgencyPIC = useMemo(() => {
        return profileData?.agency
    }, [profileData])

    const isDeveloperPIC = useMemo(() => {
        return profileData?.developer
    }, [profileData])

    return (
        <Card
            shadow='md'
            withBorder
            sx={(theme) => ({
                height: '100vh',
                [theme.fn.smallerThan("md")]: {
                    height: 'auto',
                }
            })}
            p={0}
        >
            <Flex sx={(theme) => (
                {
                    flexDirection: 'column',
                    padding: 0,
                    [theme.fn.smallerThan("md")]: {
                        height: 'auto',
                        flexDirection: 'row',
                    },
                    [theme.fn.smallerThan("sm")]: {
                        height: 'auto',
                        flexDirection: 'column',
                    }
                }
            )}>
                <Stack sx={{ padding: 16 }}>
                    <Flex
                        align='center'
                        sx={(theme) => (
                            {
                                flexDirection: 'column',
                                [theme.fn.smallerThan("md")]: {
                                    flexDirection: 'row',
                                },
                                gap: 8
                            }
                        )}
                    >
                        <Group
                            sx={(theme) => ({
                                width: '100%',
                                [theme.fn.smallerThan("sm")]: {
                                    width: 'auto',
                                },
                            })}
                            position="center"
                        >
                            <Box p={8} sx={{ border: '1px solid #000', borderRadius: 8 }}>
                                {profileIsLoading ? (
                                    <Center
                                        w={isTablet ? 75 : 150}
                                        h={isTablet ? 75 : 150}
                                    >
                                        <Loader />
                                    </Center>
                                ) : (
                                    <Avatar
                                        w={isTablet ? 75 : 150}
                                        h={isTablet ? 75 : 150}
                                        src={profile?.renImg?.url}
                                        styles={{
                                            image: {
                                                objectFit: 'contain'
                                            }
                                        }}
                                    />

                                )}
                            </Box>
                        </Group>
                        <Stack spacing={8} sx={{ flex: 1 }}>
                            <SubscriptionBadge type={currentActiveSubscription.package ?? "UNSUBBED"} />
                            {!!currentActiveSubscription.currentEndPeriod && <Text size="xs" ta="center">Expiry:{moment.unix(currentActiveSubscription.currentEndPeriod).format("DD/MM/YYYY")}</Text>}
                            <Group spacing={8} position='center'>
                                <Text align='center'>My Account</Text>
                                <Anchor href="/profile/edit">
                                    <Text size="sm">Edit</Text>
                                </Anchor>
                            </Group>
                            <Text align='center'>{profile?.renId}</Text>
                        </Stack>
                    </Flex>
                    <Stack>
                        {profile?.publicProfileSlug ? (
                            <CopyButton value={`www.propmoth.com/agent/${profile?.publicProfileSlug}`}>
                            {({ copied, copy }) => (
                                <Button onClick={copy}>{copied ? "Copied to clipboard!" : "Copy Profile Link"}</Button>
                            )}
                        </CopyButton>
                        ):(
                            <Button onClick={() => navigate("/profile/edit")}>Set Public Profile Url </Button>
                        )}
                    </Stack>
                </Stack>
                <Stack
                    spacing={4}
                    sx={(theme) => (
                        {
                            [theme.fn.smallerThan("md")]: {
                                flex: 1
                            }
                        }
                    )}
                >
                    {!isTablet && !isMobile && (
                        <Stack spacing={2}>
                            {!!isAgencyPIC && (
                                <Group
                                    p={8}
                                    px={16}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate(`/agency/edit/${isAgencyPIC?.id}`)}
                                >
                                    <Group spacing={8}>
                                        <UserSquare />
                                        <Text> Manage Agency</Text>
                                    </Group>
                                </Group>
                            )}
                            {!!isDeveloperPIC && (
                                <Group
                                    p={8}
                                    px={16}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate(`/developer/edit/${isDeveloperPIC?.id}`)}
                                >
                                    <Group spacing={8}>
                                        <UserSquare />
                                        <Text> Manage Developer</Text>
                                    </Group>
                                </Group>
                            )}
                            {profileLinks.map(l => (
                                <Box
                                    sx={{
                                        borderLeft: activeTab === l.value ? "3px solid #417256" : "3px solid transparent",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setAndSwap(l.value)}
                                >
                                    <Group p={8} px={16}>
                                        <Group spacing={8}>
                                            {l.icon}
                                            <Text>{l.label}</Text>
                                        </Group>
                                    </Group>
                                </Box>
                            ))}
                            {comingSoon.map(x => (
                                <Group p={8} px={16}>
                                    <Group spacing={8}>
                                        {x.icon}
                                        <Text>{x.label}</Text>
                                    </Group>
                                    <Badge>Coming Soon</Badge>
                                </Group>
                            ))}
                        </Stack>
                    )}
                    {!!isTablet && !isMobile && (
                        <Group p={8} px={16}>
                            {profileLinks.map(l => (
                                <Button
                                    sx={{
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setAndSwap(l.value)}
                                    variant={activeTab === l.value ? "filled" : "outline"}
                                >
                                    <Group p={8} px={16}>
                                        <Group spacing={8}>
                                            {l.icon}
                                            <Text>{l.label}</Text>
                                        </Group>
                                    </Group>
                                </Button>
                            ))}
                            {comingSoon.map(x => (
                                <Group p={8} px={16}>
                                    <Group spacing={8}>
                                        {x.icon}
                                        <Text>{x.label}</Text>
                                    </Group>
                                    <Badge>Coming Soon</Badge>
                                </Group>
                            ))}
                        </Group>
                    )}
                    {!!isMobile && (
                        <Button>
                            <Group p={8} px={16}>
                                <Group spacing={8}>
                                    {profileLinks.find(e => e.value === activeTab)?.icon}
                                    {profileLinks.find(e => e.value === activeTab)?.label}
                                </Group>
                            </Group>
                        </Button>
                    )}
                    {!!isMobile && !!mobileMenuDrop && (
                        <Stack spacing={2}>
                            {displayedProfileLinks.map(l => (
                                <Box
                                    sx={{
                                        borderLeft: activeTab === l.value ? "3px solid #417256" : "3px solid transparent",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setAndClose(l.value)}
                                >
                                    <Group p={8} px={16}>
                                        <Group>
                                            {l.icon}
                                            <Text>{l.label}</Text>
                                        </Group>
                                    </Group>
                                </Box>
                            ))}
                            {comingSoon.map(x => (
                                <Group p={8} px={16}>
                                    <Group spacing={8}>
                                        {x.icon}
                                        <Text>{x.label}</Text>
                                    </Group>
                                    <Badge>Coming Soon</Badge>
                                </Group>
                            ))}
                        </Stack>
                    )}
                    {!!isMobile && (
                        <Box py={8}>
                            <Group
                                position='center'
                                sx={{ cursor: "pointer" }}
                                onClick={() => setMobileMenuDrop(!mobileMenuDrop)}
                            >
                                <Text size="sm">{!!mobileMenuDrop ? "See Less Links" : "See More Links"}</Text>
                                {!mobileMenuDrop ? (
                                    <ChevronDown />
                                ) : (
                                    <ChevronUp />
                                )}
                            </Group>
                        </Box>
                    )}
                </Stack>
            </Flex>
        </Card>
    );
};

export default DashboardControlCard;