import { Box } from '@mantine/core';
import React from 'react';

const GreenGradientBg = ({...props}) => {
    return (
        <Box
            sx={{
                // background: "rgb(241,255,247)",
                background: "linear-gradient(180deg, rgba(241,255,247,1) 0%, rgba(201,239,219,1) 100%)"
            }}
        >
                {props.children}
        </Box>
    );
};

export default GreenGradientBg;